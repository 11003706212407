<template>
  <v-data-table
    :headers="headers"
    :items="vendors"
    class="elevation-1"
    :search="search"
    :loading="loadingTable"
  >
    <template v-slot:top>
      <div class="tw-px-2 md:tw-px-6 tw-mb-4">
        <card-title icon="mdi-account-hard-hat" :is-image="false"
          >Car Rental Vendors</card-title
        >
      </div>

      <v-dialog v-model="dialog" max-width="800px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    disabled
                    v-model="editedItem.id"
                    label="id"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.name"
                    label="Company Name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.email_address"
                    label="Company Email"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.phone_number"
                    label="Phone Number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.kra_pin"
                    label="KRA PIN"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.bank_name"
                    label="Bank Name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.bank_branch"
                    label="Bank Branch"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.bank_account"
                    label="Bank Account"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.physical_address"
                    label="Physical Address"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="save">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-btn
        small
        class="mr-3"
        color="primary"
        :to="{ name: 'VehicleTable', query: { vehicle: JSON.stringify(item) } }"
      >
        <v-icon> mdi-car </v-icon>
      </v-btn>

      <v-icon small class="mr-3" color="secondary" @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon small color="error" @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import { mapState } from 'vuex'
import CardTitle from '@/components/CardTitle'
export default {
  components: { CardTitle },
  data: () => ({
    dialog: false,
    search: '',
    headers: [
      {
        text: 'Id',
        align: 'start',
        sortable: true,
        value: 'id',
      },
      {
        text: 'Company Name',
        align: 'start',
        sortable: true,
        value: 'name',
      },
      {
        text: 'Company Email',
        align: 'start',
        sortable: true,
        value: 'email_address',
      },
      { text: 'Phone Number', value: 'phone_number' },
      { text: 'KRA PIN', value: 'kra_pin' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      id: '',
      companyName: '',
      companyEmail: '',
      phoneNumber: '',
      kraPin: '',
      bankName: '',
      bankBranch: '',
      bankAccount: '',
      address: '',
    },
    defaultItem: {
      companyName: '',
      companyEmail: '',
      phoneNumber: '',
      kraPin: '',
      bankName: '',
      bankBranch: '',
      bankAccount: '',
      address: '',
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    ...mapState(['vendors', 'loadingTable', 'loadingText']),
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  mounted() {
    this.$store.dispatch('fetchVendors')
  },
  created() {
    this.$store.state.loadingTable = true
  },

  methods: {
    editItem(item) {
      this.editedIndex = this.vendors.indexOf(item)
      this.editedItem = { ...item }
      this.dialog = true
    },

    deleteItem(item) {
      const index = this.vendors.indexOf(item)
      confirm('Are you sure you want to delete this item?') &&
        this.vendors.splice(index, 1)
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        this.desserts[this.editedIndex] = { ...this.editedItem }
      } else {
        this.desserts.push(this.editedItem)
      }
      this.close()
    },
  },
}
</script>
